import { Search } from 'auto-libs'
import { Button, Dialog, Layout, Loading, Toast } from 'auto-ui'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSetState } from 'react-use'
import http from '../../utils/http'
import { aliPay, wxPay } from '../../utils/pay'
import { getCountDown } from '../../utils/time'
import './style.scss'

const Pay = () => {
  const navigateHook = useNavigate()
  const { orderNo, d, url } = Search.parse()

  let timer: any = null
  let payWay = 'WXPAY'
  let leftTimes = 3

  const [state, setState] = useSetState<any>({
    passThrouging: false,
    passThroughImage: '',
    photoAnimation: false,
    isSrartingAd: false,
    time: 60 * 60 - 1,
    disCountVisible: false,
    disCountTime: 10 * 60 - 1,
    alipayForm: null,
    url,
  })

  const { photoAnimation } = state

  const countdown = (time: number, f: any) => {
    let _time = time
    timer = setTimeout(() => {
      --_time
      if (_time === 0) {
        clearTimeout(_time)
        return
      }
      f(_time)
      countdown(_time, f)
    }, 1000)
  }

  const onWechatPay = async (discount?: string) => {
    try {
      Loading()

      const result = await wxPay(orderNo, discount)

      leftTimes = 3

      window.location.href = result
    } catch (error: any) {
      Toast(error.message || '系统异常，请重试')
    } finally {
      Loading.hide()
    }
  }

  const onAlipay = async (discount?: string) => {
    try {
      Loading()

      const result = await aliPay(orderNo, discount)

      setState({ alipayForm: result })

      setTimeout(() => {
        document.forms[0].submit()
      }, 100)

      leftTimes = 3
    } catch (error) {
      Toast('系统异常，请重试')
    } finally {
      Loading.hide()
    }
  }

  const fetchMatchResult = async () => {
    try {
      if (!orderNo) {
        Toast('地址不正确，请回首页重新测试')
        return
      }

      const payResult: any = await http.request({
        url: '/order/queryStatus',
        method: 'POST',
        data: {
          orderId: orderNo,
        },
      })

      let { payMethod, status, orderParams } = payResult.body
      if (payResult.code !== '00000000' || status !== 2) {
        payWay = payMethod

        --leftTimes
        if (leftTimes > 0) {
          fetchMatchResult()
          return
        }

        let _orderParams: any = {}
        try {
          _orderParams = JSON.parse(orderParams)
        } catch (e) {
          _orderParams = {}
        }

        setState({
          disCountVisible: true,
          url: 'https://ijianshui.oss-cn-shanghai.aliyuncs.com/' + _orderParams.filePath || url,
        })
        countdown(10 * 60 - 1, (time: number) => {
          setState({
            disCountTime: time,
          })
        })
      } else {
        navigateHook(`/result?orderNo=${orderNo}`, { replace: true })
      }
    } catch (e: any) {
      Toast(e.message || '系统异常，请重试')
    }
  }

  const toPay = async () => {
    try {
      Loading()

      setState({
        disCountVisible: false,
      })

      navigateHook(`/pay?orderNo=${orderNo}`, { replace: true })

      const payResult: any = await http.request({
        url: '/order/queryStatus',
        method: 'POST',
        data: {
          orderId: orderNo,
        },
      })

      let { payMethod, status } = payResult.body
      if (payResult.code !== '00000000' || status !== 2) {
        payWay = payMethod

        if (payWay === 'WXPAY') {
          await onWechatPay('1')
        } else {
          await onAlipay('1')
        }
      } else {
        navigateHook(`/result?orderNo=${orderNo}`, { replace: true })
      }
    } catch (error: any) {
      Toast(error.message || '系统异常，请重新再试')
    } finally {
      Loading.hide()
    }
  }

  const onRefuse = async () => {
    try {
      setState({
        disCountVisible: false,
      })

      const payResult: any = await http.request({
        url: '/order/queryStatus',
        method: 'POST',
        data: {
          orderId: orderNo,
        },
      })

      let { status } = payResult.body
      if (!(payResult.code !== '00000000' || status !== 2)) {
        navigateHook(`/result?orderNo=${orderNo}`, { replace: true })
      }
    } catch (error) {}
  }

  useEffect(() => {
    countdown(60 * 60 - 1, (time: number) => {
      setState({
        time,
      })
    })

    if (d === 'd') {
      // 说明是从取消支付中来的
      fetchMatchResult()
    }

    return () => {
      timer && clearTimeout(timer)
    }
  }, [])

  return (
    <Layout className="page-pay" useBottomSafeArea={false}>
      <Layout.Body>
        <div className="slogan" />
        <div className="photo-ad">
          <div className="photo-pass-wrapper">
            <div
              className={`item local ${photoAnimation ? 'animation' : ''}`}
              style={{ background: `url(${state.url}) no-repeat 50% / cover` }}
            />
          </div>

          <div className="price">
            限时特价：<em>￥9.9</em>
          </div>

          <div className="countdown">倒计时：{getCountDown(state.time)}</div>

          <div className="pay-button">
            <Button className="btn wechat" onClick={() => onWechatPay()}>
              <span className="icon" />
              微信支付
            </Button>
            <Button className="btn alipay" onClick={() => onAlipay()}>
              <span className="icon" />
              支付宝支付
            </Button>
          </div>

          <div className="tip">
            <p className="count">
              已有<em>47808</em>人进行了测试
            </p>
          </div>
        </div>

        <div
          id="alipay_form"
          style={{ display: 'none' }}
          dangerouslySetInnerHTML={{ __html: state.alipayForm }}
        />
      </Layout.Body>

      <Dialog className="pop-red-pack" visible={state.disCountVisible} height="525px">
        <div className="inner">
          <h1>恭喜您获得红包！</h1>
          <h2>
            3.00<sup>元</sup>
          </h2>
          <h6>查看配对测试结果立减</h6>

          <div className="countdown">{getCountDown(state.disCountTime)}后失效</div>

          <div className="btns">
            <Button className="btn ok" onClick={toPay}>
              立即使用
            </Button>
            <Button className="btn cancel" onClick={onRefuse} hollow>
              残忍拒绝
            </Button>
          </div>
        </div>
      </Dialog>
    </Layout>
  )
}

export default Pay
