import { Search } from 'auto-libs'
import { Layout, Loading, Toast } from 'auto-ui'
import { useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import http from '../../utils/http'
import { sleepSize } from '../../utils/index'
import { getStorageUUID } from '../../utils/storage'
import './style.scss'

export default function PageIndex() {
  const navigate = useNavigate()
  const channel = Search.get('channel') || ''

  let uploadFile: any = useRef(null)

  const onFileChange = async (e: any) => {
    try {
      Loading()

      const file = e.target.files[0]

      const awaitFile = await sleepSize(file)

      if (!awaitFile) {
        Toast('图片上传失败，请重新上传或尝试拍照上传')
        return
      }

      if (file.type.indexOf('image') < 0) {
        Toast('请上传正确的图片类型')
        return
      }

      const formData = new FormData()
      formData.append('file', file)

      const res: any = await http.request({
        url: '/file/upload',
        data: formData,
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })

      if (res.code !== '00000000') {
        Toast('图片上传失败，请重试')
        return
      }

      const result: any = await http.request({
        url: '/order/create',
        method: 'POST',
        data: {
          productType: '7', // 1:姓名匹对，2：智商测试 ,3: MBTI,4:时光穿梭机
          channel,
          filePath: res.body.filePath,
        },
      })

      if (result.code !== '00000000') {
        Toast('请求失败，请再试')
        return
      }

      uploadFile.current.value = null

      navigate(`/pay?url=${res.body.viewUrl}&orderNo=${result.body.orderId}`, { replace: false })
    } catch (e: any) {
      Toast(e.msg || '系统错误')
    } finally {
      Loading.hide()
    }
  }

  const statistics = async () => {
    const params: any = { userId: getStorageUUID() }

    if (channel) {
      params.channel = channel
    }

    await http.request({
      url: '/statistics/submit',
      method: 'POST',
      data: params,
    })
  }

  useEffect(() => {
    statistics()
  }, [])

  return (
    <Layout className="page-index" useBottomSafeArea={false}>
      <Layout.Body>
        <div className="inner">
          <div className="slogan" />

          <div className="gallery">
            <div className="photo" />
          </div>

          <div className="photo-tip">
            <div className="item">
              <span>1</span>
              <h6>上传照片</h6>
            </div>
            <div className="line" />
            <div className="item">
              <span>2</span>
              <h6>AI智能合成</h6>
            </div>
            <div className="line" />
            <div className="item">
              <span>3</span>
              <h6>生成效果</h6>
            </div>
          </div>

          <div className="btns">
            <div className="tip">
              <p>现在超火的漫画脸你拥有了吗？</p>
              <p>快来测一测你的漫画脸有多好看</p>
            </div>
            <div className="btn" />
            <input
              type="file"
              ref={uploadFile}
              accept="image/*"
              className="file"
              onChange={onFileChange}
            />
          </div>
        </div>
      </Layout.Body>
    </Layout>
  )
}
